import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Flex, Title, Label } from "../../components/system"
import Blocks from "../../components/Blocks"

const DataPolicy = () => {
  const data = useStaticQuery(graphql`
    query dataPolicy {
      sanityContent(_id: { eq: "cprivacyPolicy" }) {
        _rawContent
      }
    }
  `)
  return (
    <Flex flexDirection="column" pt={4}>
      <Label fontSize={1} color="faded">
        Legal
      </Label>
      <Title fontSize={[6, 7]} mb={5} color="secondary">
        Data Policy
      </Title>
      {data.sanityContent && (
        <Blocks px={[3, 7]} data={data.sanityContent._rawContent} />
      )}
    </Flex>
  )
}

export default DataPolicy
